export const webEngageUserAttributes = (data) => {
  Object.entries(data).map((item) => window?.webengage?.user.setAttribute(item?.[0], item?.[1]));
};

export const webEngageLoginTrack = (user) => {
  const { id: userId, email } = user;
  userId && window?.webengage?.user.login(userId);
  email && window?.webengage?.user.setAttribute('we_email', email);
};

export const webEngageLogoutSession = () => window?.webengage?.user.logout();
