import { createSlice } from '@reduxjs/toolkit';
import {
  getReferralPrograms,
  getReferrarInfo,
  getShareAndEarnConfig,
} from '../services/referralApiService';
import { REFERRAL_RUPEES_TO_COINS } from 'src/aws-exports';

const referralUrlBase = process.env.REACT_APP_REFERRAL_URL || 'https://referral-dev.almagrow.com/';

const initialState = {
  referralPrograms: [],
  shareAndEarnConfig: {
    signupAmount: 0,
    redemptionTDSPercent: 0,
  },
  referralInfo: {},
};

const shareAndEarnSlice = createSlice({
  name: 'shareAndEarnReducer',
  initialState,
  reducers: {
    updateReferralPrograms: (state, action) => {
      state.referralPrograms = action.payload;
    },
    updateShareAndEarnConfig: (state, action) => {
      state.shareAndEarnConfig = action.payload;
    },
    updateReferralInfo: (state, action) => {
      state.referralInfo = action.payload;
    },
  },
});

export function fetchReferralPrograms() {
  return async (dispatch) => {
    try {
      const res = await getReferralPrograms();
      dispatch(updateReferralPrograms(res));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchShareAndEarnAmt() {
  return async (dispatch) => {
    try {
      const res = await getShareAndEarnConfig();
      const amt = (res?.signupAmount / 100) * REFERRAL_RUPEES_TO_COINS;
      const tds = res?.redemptionTDSPercent;
      dispatch(
        updateShareAndEarnConfig({
          signupAmount: amt,
          redemptionTDSPercent: tds,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchReferralInfo() {
  return async (dispatch) => {
    try {
      const res = await getReferrarInfo();
      res.referralUrl = referralUrlBase + res?.referralCode;
      dispatch(updateReferralInfo(res));
    } catch (error) {
      console.error(error);
    }
  };
}

export const selectReferralPrograms = (state) => state.shareAndEarnReducer.referralPrograms;
export const selectShareAndEarnAmt = (state) => state.shareAndEarnReducer.shareAndEarnConfig;
export const selectReferralInfo = (state) => state.shareAndEarnReducer.referralInfo;

export const { updateReferralPrograms, updateShareAndEarnConfig, updateReferralInfo } =
  shareAndEarnSlice.actions;

export default shareAndEarnSlice.reducer;
