import { API } from 'aws-amplify';
import { LEARN_SUBMISSION_API_ENDPOINT_NAME, USER_API_GATEWAY_ENDPOINT_NAME } from '../aws-exports';
import { returnCourseApiName } from './getcourse';

// remove
export const fetchSubscriptionPlans = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/v2/subscription-plan');
};

export const initializePayment = async (payment) => {
  const course = returnCourseApiName(payment.goalType);
  payment = { ...payment, goalType: course };
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/payment/init/razorpay', {
    body: payment,
  });
};

export const verifyPayment = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/payment/verify/razorpay`, {
    body,
  });
};

export const getJWTfreshDesk = async (nonce) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/freshdesk/jwt?nonce=${nonce}`);

export const talkToTeamTrigger = async (body) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/payment/talk-to-admissions-team`,
    {
      body,
    }
  );
};

// cash rewards
export const getCashRewards = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/payout/request`, {
    body,
  });
};

export const getDiscountDetails = async (apiData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/payment/application/details', {
    body: apiData,
  });
};

// currently in use
export const getPaymentDetails = async (apiData) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/v3/payment/payment-details?courseType=${apiData?.courseType}&planType=${apiData?.planType}`
  );
};

export const applyPaymentCouponV2 = async (apiData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/v3/payment/apply-coupon`, {
    body: apiData,
  });
};

export const initPayment = async (apiData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/v3/payment/init', {
    body: apiData,
  });
};

// confirm payment event for google analytics
export const getPaymentAnalyticsConfirmation = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/site-analytics-marker');
};

export const updatePaymentAnalyticsConfirmation = async (apiData) => {
  return await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/site-analytics-marker', {
    body: apiData,
  });
};
