import { createSlice } from '@reduxjs/toolkit';
import { getAllReferrals } from '../services/referralApiService';

const initialState = {
  referralAmount: 0,
  referralCode: '',
  balance: 0,
  lifetimeRewards: 0,
  referredUsers: [],
  vouchers: [],
  loading: false,
  loadingOverlay: false,
  showGiftCardInformation: false,
  showRedeemConfirmationOverlay: false,
  showRedeemedMessageOverlay: false,
  giftCardInformation: {},
  redeemedVouchers: [],
  referreDetails: {},
  selectedVoucherDetails: {},
  showGiftOverlay: false,
  placeOrderResponse: {
    errorOccured: false,
    orderDetails: {},
    errorMessage: '',
  },
  showVoucherBlock: true,
  utmParams: {},
  refreeApiCalled: false,
};

const referAndEarnSlice = createSlice({
  name: 'referAndEarnReducer',
  initialState,
  reducers: {
    setUserReferralDetails: (state, action) => {
      return {
        ...state,
        referralAmount: action.payload.referralAmount,
        referralCode: action.payload.referralCode,
        balance: action.payload.balance,
        lifetimeRewards: action.payload.lifetimeRewards,
        referredUsers: action.payload.referredUsers,
        utmParams: action.payload.utmParams,
      };
    },

    setReferredUsers: (state, action) => {
      return {
        ...state,
        referredUsers: action.payload.referredUsers,
      };
    },

    setLoadingStatus: (state, action) => {
      return { ...state, loading: action.payload.loadingStatus };
    },

    setLoadingOverlayStatus: (state, action) => {
      return { ...state, loadingOverlay: action.payload.loadingOverlay };
    },
    setRefreeApiCalled: (state, action) => {
      return {
        ...state,
        refreeApiCalled: action.payload,
      };
    },

    setVouchersDetails: (state, action) => {
      return {
        ...state,
        vouchers: action.payload.vouchers,
      };
    },

    // To Show Gift Card Overlay Or Not
    setGiftCardInformationFlag: (state, action) => {
      return {
        ...state,
        showGiftCardInformation: action.payload.showGiftCardInformation,
      };
    },

    // To Show Redeem Confirmation Overlay or Not
    setRedeemConfirmationOverlay: (state, action) => {
      return {
        ...state,
        showRedeemConfirmationOverlay: action.payload.showRedeemConfirmationOverlay,
      };
    },

    // To show Redeemed Message Overlay or not
    setRedeemedMessageOverlay: (state, action) => {
      return {
        ...state,
        showRedeemedMessageOverlay: action.payload.showRedeemedMessageOverlay,
      };
    },

    // To Set GiftCard Information Object
    setGiftCardInformation: (state, action) => {
      return {
        ...state,
        giftCardInformation: action.payload.giftCardInformation,
      };
    },

    // To set Redeemed Vouchers
    setRedeemedVouchers: (state, action) => {
      return {
        ...state,
        redeemedVouchers: action.payload.redeemedVouchers,
      };
    },

    // To set Referr Details - Details of the user whose REFERRAL CODE being used by the Current User
    setReferrUserDetails: (state, action) => {
      return {
        ...state,
        referreDetails: action.payload.referreDetails,
        showGiftOverlay: action.payload.showGiftOverlay,
        refreeApiCalled: action.payload.refreeApiCalled,
      };
    },

    // To show Gift Overlay on the Payment/Checkout Page or Not
    setGiftOverlay: (state, action) => {
      return {
        ...state,
        showGiftOverlay: action.payload.showGiftOverlay,
      };
    },

    setSelectedVoucherDetails: (state, action) => {
      return {
        ...state,
        selectedVoucherDetails: action.payload.selectedVoucherDetails,
      };
    },

    // To Set PlaceOrderResponse
    setPlaceOrderResponse: (state, action) => {
      return {
        ...state,
        placeOrderResponse: action.payload.placeOrderResponse,
      };
    },

    // To Set the ShowVoucherBlock status in GiftCard Modal
    setVoucherBlock: (state, action) => {
      return { ...state, showVoucherBlock: action.payload.showVoucherBlock };
    },
  },
});

export const {
  setUserReferralDetails,
  setLoadingStatus,
  setVouchersDetails,
  setGiftCardInformationFlag,
  setRedeemConfirmationOverlay,
  setRedeemedMessageOverlay,
  setGiftCardInformation,
  setRedeemedVouchers,
  setReferrUserDetails,
  setSelectedVoucherDetails,
  setGiftOverlay,
  setPlaceOrderResponse,
  setLoadingOverlayStatus,
  setVoucherBlock,
  setRefreeApiCalled,
  setReferredUsers,
} = referAndEarnSlice.actions;
export default referAndEarnSlice.reducer;

export const getReferrals = () => async (dispatch) => {
  const referredUsers = await getAllReferrals();
  dispatch(setReferredUsers({ referredUsers }));
};
