import { API } from 'aws-amplify';
import {
  USER_API_GATEWAY_ENDPOINT_NAME,
  LEARN_SUBMISSION_API_ENDPOINT_NAME,
} from '../../aws-exports';
import { BOOKING_STAGE } from '../../constants';

export const getAllThemes = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/theme', {});

export const createBooking = async (body) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking', { body });

export const getMyBookings = async (courseType) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/filter-bookings/student', {
    body: {
      courseType,
      includeSubTopic: true,
      includeBookingProblems: true,
      includeCoach: true,
    },
  });

export const reportProblem = async (body) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/student/booking/problem', { body });

export const getBookingDetails = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking', {
    queryStringParameters: { bookingId },
  });

export const submitStudentRating = async (body) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/student/booking/rating', { body });

export const cancelBooking = async (bookingId) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/student/booking', {
    body: {
      id: bookingId,
      stage: BOOKING_STAGE.CANCELLED,
    },
  });

export const updatePreferences = async (booking) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/student/booking', {
    body: {
      id: booking.id,
      preferences: booking.preferences,
      stage: BOOKING_STAGE.TIME_SLOTS_ADDED,
    },
  });

export const getAppToken = async ({ bookingId, userId, role }) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/app-token', {
    body: {
      bookingId,
      userId,
      role,
    },
  });

export const getBookingQuestions = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/question', {
    queryStringParameters: { bookingId },
  });

export const getQuestionLevels = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/question-module', {});

export const getRatingTemplate = async (ratingTemplateId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/rating-template', {
    queryStringParameters: { ratingTemplateId },
  });

export const getBookingRecordings = async (roomId100ms) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/browserRecordings', {
    queryStringParameters: { roomId100ms },
  });

export const getMentorshipSchedule = async (courseType) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/schedule/${courseType}`, {});

export const getMentorList = async (courseType) =>
  await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/strapi/mentors?courseType=${courseType}`,
    {}
  );

export const getBookingDetailsByBookingId = async (bookingId) => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking', {
    queryStringParameters: { bookingId },
  });
};
