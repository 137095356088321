import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Provider } from 'react-redux';
import store from './redux/store';
import { MyErrorBoundary } from './ErrorBoundary';
import './static/css/index.css';
import LinkedInTag from 'react-linkedin-insight';
import 'src/plugins/dayjs';
import MatomoWrapper from './plugins/matomoWrapper';

if (process.env.NODE_ENV !== 'development') {
  LinkedInTag.init('2558316', 'px', false);
}

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <MyErrorBoundary>
      <MatomoWrapper>
        <App />
      </MatomoWrapper>
    </MyErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
