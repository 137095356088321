import { createSlice } from '@reduxjs/toolkit';
import { COURSE_TYPE } from 'src/constants';
import { returnCourseApiName, returnRespectiveMastersCourse } from 'src/services/getcourse';
import { getFreeUserFaq } from 'src/services/userApiService';
import { returnActualCourseNameForApi } from '../services/getcourse';
import {
  getAllEvents,
  getCommunityUpdates,
  getEventBookings,
  getMentors,
  getStories,
  getUpcomingClasses,
  registerEvent,
  getCommunityUpdatesForEnrolledUsers,
  getUpcomingDemoClass,
} from '../services/homeTabApiService';

const homeSlice = createSlice({
  name: 'homeReducer',
  initialState: {
    upcomingClasses: null,
    courseData: null,
    mentors: null,
    events: null,
    successStories: null,
    hideScrollbar: false,
    loadData: false,
    eventsData: null,
    userFAQ: null,
    communityUpdates: null,
    communityUpdatesForEnrolledUsers: null,
    coupon: {
      status: false,
      value: null,
    },
    mastersCourseData: null,
    ugCourseData: null,
    openCallBack: false,
    demoClass: null,
  },
  reducers: {
    setUpcomingClasses: (state, action) => {
      state.upcomingClasses = action.payload;
    },
    updateMentors: (state, action) => {
      state.mentors = action.payload;
    },
    getEvents: (state, action) => {
      state.events = action.payload;
    },
    setSuccessStories: (state, action) => {
      state.successStories = action.payload;
    },
    setHideScrollbar: (state, action) => {
      state.hideScrollbar = action.payload;
    },
    setLoadData: (state, action) => {
      state.loadData = action.payload;
    },
    resetHomeData: (state, action) => {
      state.upcomingClasses = null;
      state.mentors = null;
      state.events = null;
      state.successStories = null;
      state.eventsData = null;
    },
    setEventsData: (state, action) => {
      state.eventsData = action.payload;
    },
    setUserFAQ: (state, action) => {
      state.userFAQ = action.payload;
    },
    setCommunityUpdates: (state, action) => {
      state.communityUpdates = action.payload;
    },
    setCommunityUpdatesForEnrolledUsers: (state, action) => {
      state.communityUpdatesForEnrolledUsers = action.payload;
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    setMastersCourseData: (state, action) => {
      state.mastersCourseData = action.payload;
    },
    setUgCourseData: (state, action) => {
      state.ugCourseData = action.payload;
    },
    setOpenCallBack: (state, action) => {
      state.openCallBack = action.payload;
    },
    setDemoClass: (state, action) => {
      state.demoClass = action.payload;
    },
  },
});

export const {
  setDemoClass,
  setUpcomingClasses,
  setHideScrollbar,
  updateMentors,
  getEvents,
  setSuccessStories,
  resetHomeData,
  setLoadData,
  setEventsData,
  setUserFAQ,
  setCommunityUpdates,
  setCommunityUpdatesForEnrolledUsers,
  setCoupon,
  setMastersCourseData,
  setUgCourseData,
  setOpenCallBack,
} = homeSlice.actions;

export const getFAQ = (state) => state.homeReducer.userFAQ;
export const communityUpdates = (state) => state.homeReducer.communityUpdates;
export const communityUpdatesForEnrolledUsers = (state) =>
  state.homeReducer.communityUpdatesForEnrolledUsers;
export const getCoupon = (state) => state.homeReducer.coupon;
export const getMastersCourseData = (state) => state.homeReducer.mastersCourseData;

export const getUgCourseData = (state) => state.homeReducer.ugCourseData;

export const fetchUserFaq = () => {
  return async (dispatch) => {
    try {
      const result = await getFreeUserFaq();
      dispatch(setUserFAQ(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchCommunityUpdates = () => {
  return async (dispatch, getState) => {
    try {
      const data = await getCommunityUpdates();
      const mappedData = data?.map((singleDataObject, index) => {
        return { ...singleDataObject, isOnline: Math.random() > 0.5 };
      });
      dispatch(setCommunityUpdates(mappedData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchCommunityUpdatesForEnrolledUsers = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const courseName = returnCourseApiName(actualCourseSelected || courseSelected);
      const modifiedCourse = returnActualCourseNameForApi(courseName);
      const data = await getCommunityUpdatesForEnrolledUsers(modifiedCourse);
      const mappedData = data?.map((singleDataObject, index) => {
        return { ...singleDataObject, isOnline: Math.random() > 0.5 };
      });
      dispatch(setCommunityUpdatesForEnrolledUsers(mappedData));
    } catch (err) {
      console.error(err);
    }
  };
};

export const upcomingClasses = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const courseName = returnCourseApiName(actualCourseSelected || courseSelected);
      const modifiedCourse = returnActualCourseNameForApi(courseName);
      const cohortDetails = userSubscription[modifiedCourse]?.cohort?.name;
      if (cohortDetails) {
        const result = await getUpcomingClasses(cohortDetails);
        dispatch(setUpcomingClasses(result));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getMentorsData = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;

      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);

      const result = await getMentors(modifiedCourse);

      dispatch(updateMentors(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchEvents = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnCourseApiName(actualCourseSelected || courseSelected);

      const isProLiveSubscription = userSubscription?.[modifiedCourse]?.planType === 'pro_live';
      const subscriptionCourse = userSubscription?.[modifiedCourse]?.courseType;

      const isUgSubscription =
        isProLiveSubscription &&
        userSubscription?.[modifiedCourse]?.courseType === COURSE_TYPE.UG_DIPLOMA_CS;

      if (isUgSubscription) return;

      const finalCourse = isProLiveSubscription
        ? returnRespectiveMastersCourse(subscriptionCourse)
        : modifiedCourse;

      const result = await getAllEvents(finalCourse);
      if (result.length) {
        result.sort(function compare(a, b) {
          const dateA = new Date(a?.attributes?.startTime);
          const dateB = new Date(b?.attributes?.startTime);
          const dateATime = dateA.getTime();
          const dateBTime = dateB.getTime();
          if (!isNaN(dateATime) && !isNaN(dateBTime)) {
            return dateA - dateB;
          } else if (!isNaN(dateATime)) {
            return -1;
          } else if (!isNaN(dateBTime)) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      dispatch(setEventsData(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const registerUserForEvent = () => {
  return async (dispatch, getState) => {
    try {
      return await registerEvent();
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserEventBookings = () => {
  return async (dispatch, getState) => {
    try {
      const result = await getEventBookings();
      dispatch(getEvents(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getSuccessStroies = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const result = await getStories(modifiedCourse);
      dispatch(setSuccessStories(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchUpcomingDemoClass = (forced = false) => {
  return async (dispatch, getState) => {
    try {
      const { demoClass } = getState().homeReducer;
      if (demoClass && !forced) return;
      const demoClassList = await getUpcomingDemoClass();
      dispatch(setDemoClass(demoClassList));
    } catch (error) {
      console.error('[UPCOMING DEMO CLASS ERROR]', error);
    }
  };
};

export default homeSlice.reducer;
