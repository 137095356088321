import { Auth } from 'aws-amplify';
import _refiner from 'refiner-js';
import { successCallbackAfterFeedbackSubmission } from 'src/redux/learnTabSlice';

export const giveFeedback = () => {
  try {
    _refiner('setProject', '08eb9120-3a60-11ed-8367-ef275961c15f');
    getUserDetails().then((res) => {
      return _refiner('identifyUser', res);
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};

async function getUserDetails() {
  try {
    return {
      id: (await Auth.currentSession()).getAccessToken().payload?.username,
      email: (await Auth.currentSession()).getIdToken().payload?.email,
    };
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const submitLessonActivity = (params) => {
  try {
    const formId = params?.formId;
    _refiner('showForm', formId, true);
    _refiner('addToResponse', {
      lessonId: params?.lessonId,
      chapterId: params?.chapterId,
      moduleId: params?.moduleId,
      courseId: params?.courseId,
    });
    _refiner('onClose', function (formId, responseData) {
      if (formId === params?.formId) {
        _refiner('addToResponse', null);
        _refiner('onClose', null);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const submitFeedback = (params) => {
  try {
    _refiner('showForm', '08f97a00-3a60-11ed-8d33-775d77547f21', true);
    _refiner('addToResponse', {
      chapterName: params?.data?.chapterName,
      currentUrl: window.location.pathname,
      lessonName: params?.data?.lesson_name,
      moduleName: params?.data?.moduleName,
      moduleId: params?.data?.moduleId,
      chapterId: params?.data?.chapterId,
      lessonId: params?.data?.lessonId,
    });
    _refiner('onClose', function (formId, responseData) {
      if (formId === '08f97a00-3a60-11ed-8d33-775d77547f21') {
        params?.setShowModal(true);
      }
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const submitCapStoneProjectFeedback = (params) => {
  try {
    _refiner('showForm', 'ac4aeb20-255c-11ee-8942-e10607cb9e7a', true);
    _refiner('addToResponse', {
      project_name: params?.projectName,
      acceptance_status: params?.adminStatus,
    });
    _refiner('onClose', function (formId, responseData) {
      if (formId === 'ac4aeb20-255c-11ee-8942-e10607cb9e7a') {
        _refiner('addToResponse', null);
        _refiner('onClose', null);
      }
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const submitLiveClassroomFeedback = (params) => {
  try {
    _refiner('showForm', 'e8ea6cc0-0a8f-11ee-822d-5d6c96a35d5f', true);
    _refiner('addToResponse', {
      email: params?.email,
      activityId: params?.activityId,
    });
    _refiner('onComplete', function (formId, responseData) {
      if (formId === 'e8ea6cc0-0a8f-11ee-822d-5d6c96a35d5f') {
        params?.dispatch(successCallbackAfterFeedbackSubmission(params, responseData));
      }
      _refiner('addToResponse', null);
      _refiner('onComplete', null);
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const submitCourseFeedbackAfter30 = (params) => {
  try {
    getUserDetails()
      .then((res) => {
        const progress = { ...res, courseProgress: 30 };
        _refiner('identifyUser', progress);
      })
      .then(() => {
        return _refiner('showForm', '56cd8f70-3a64-11ed-a3ad-b1b44d53490e');
      });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const submitCourseFeedbackAfter60 = (params) => {
  try {
    getUserDetails()
      .then((res) => {
        const progress = { ...res, courseProgress: 60 };
        _refiner('identifyUser', progress);
      })
      .then(() => {
        return _refiner('showForm', '1902e540-43b3-11ed-8e1f-cdfcaff8d040');
      });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const submitCourseFeedbackComplete = (params) => {
  try {
    getUserDetails()
      .then((res) => {
        const progress = { ...res, courseProgress: 100 };
        _refiner('identifyUser', progress);
      })
      .then(() => {
        return _refiner('showForm', '1f3700e0-43b3-11ed-9931-994d52f01a15');
      });
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const resetRefinerForm = () => {
  try {
    _refiner('resetUser');
  } catch (error) {
    console.error(error);
    return false;
  }
};
