import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { COURSE_TYPE, PLAN_TYPE } from '../constants';
import { returnActualCourseNameForApi, returnCourseCamelCaseName } from '../services/getcourse';
import {
  checkSubscription,
  fetchUserPerformanceData,
  fetchUserPerformanceHoverboxData,
  getOnboardingData,
} from '../services/userApiService';

const initialState = {
  subscriptionDetails: null,
  onBoardingData: null,
  userPerformance: null,
  userPerformanceHoverbox: null,
  paymentCompleted: null,
  profileOnboardingData: {
    jobTitles: [],
    companies: [],
    educationStream: [],
    degree: [],
  },
  hideFreshDeskWidget: false,
  freshDeskClass: '',
  onboardingCourse: null,
  isPhoneVerified: false,
  onboardingLoaded: false,
};

const userSubscriptionSlice = createSlice({
  name: 'userSubscriptionReducer',
  initialState,
  reducers: {
    setUserSubscription: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
    setUserOnboardingData: (state, action) => {
      state.onBoardingData = action.payload;
    },
    setUserPerformance: (state, action) => {
      state.userPerformance = action.payload;
    },
    setUserPerformanceHoverbox: (state, action) => {
      state.userPerformanceHoverbox = action.payload;
    },
    setPaymentCompleted: (state, action) => {
      state.paymentCompleted = action.payload;
    },
    setProfileOnboardingData: (state, action) => {
      state.profileOnboardingData = action.payload;
    },
    setHideFreshDeskWidget: (state, action) => {
      state.hideFreshDeskWidget = action.payload;
    },
    setFreshDeskClass: (state, action) => {
      state.freshDeskClass = action.payload;
    },
    setOnboardingCourse: (state, action) => {
      state.onboardingCourse = action.payload;
    },
    setIsPhoneVerified: (state, action) => {
      state.isPhoneVerified = action.payload;
    },
    setOnboardingLoaded: (state, action) => {
      state.onboardingLoaded = action.payload;
    },
  },
});

export const {
  setUserSubscription,
  setUserOnboardingData,
  setUserPerformance,
  setUserPerformanceHoverbox,
  setPaymentCompleted,
  setProfileOnboardingData,
  setHideFreshDeskWidget,
  setFreshDeskClass,
  setOnboardingCourse,
  setIsPhoneVerified,
  setOnboardingLoaded,
} = userSubscriptionSlice.actions;

export const userSubscriptionSelector = (state) =>
  state.userSubscriptionReducer.subscriptionDetails;

export const isPhoneVerified = (state) => state.userSubscriptionReducer.isPhoneVerified;

export const userDataScienceSubscription = (state) =>
  state.userSubscriptionReducer.subscriptionDetails[COURSE_TYPE.DATA_SCIENCE] || {};
export const userWeb3Subscription = (state) =>
  state.userSubscriptionReducer.subscriptionDetails[COURSE_TYPE.WEB_DEV];

export const onboardingSelector = (state) => state.userSubscriptionReducer.onBoardingData;
export const onboardingCourseSelector = (state) => state.userSubscriptionReducer.onboardingCourse;

export function fetchUserSubscription({ forced = true } = { forced: true }) {
  return async (dispatch, getState) => {
    try {
      const existingUserSubscription = userSubscriptionSelector(getState());
      if (forced || !existingUserSubscription) {
        const userSubscription = await checkSubscription();
        const userSubObject = {};
        userSubscription.map(
          (subscription) => (userSubObject[subscription.courseType] = subscription)
        );
        dispatch(setUserSubscription(userSubObject));
        return userSubObject;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function upsertUserSubscription(newSubscription) {
  return (dispatch, getState) => {
    try {
      const existingUserSubscription = { ...userSubscriptionSelector(getState()) };
      if (newSubscription.planType !== PLAN_TYPE.NEW_USER) {
        existingUserSubscription[newSubscription.courseType] = newSubscription;
        dispatch(setUserSubscription(existingUserSubscription));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

const sortPerformanceData = (data, track) => {
  const cloneData = _.cloneDeep(data);
  const moduleSlugs = track?.learn_modules?.map((i) => i?.attributes?.name) ?? [];
  const sortedData = [...cloneData?.modulesData].sort((a, b) => {
    const slugA = a?.name ?? '';
    const slugB = b?.name ?? '';
    const indexA = moduleSlugs.indexOf(slugA);
    const indexB = moduleSlugs.indexOf(slugB);
    return indexA - indexB;
  });
  cloneData.modulesData = sortedData;
  return cloneData;
};

export function getUserPerformance(courseData) {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)?.[0];
      const actualCourseSelected = getState().courseReducer?.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const data = await fetchUserPerformanceData({
        course: returnCourseCamelCaseName(modifiedCourse),
        id: courseData?.courseId,
      });
      const sortedData = sortPerformanceData(data, courseData?.FOUNDATION_TRACK) ?? data ?? {};
      dispatch(setUserPerformance(sortedData));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getUserPerformanceHoverbox() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)?.[0];
      const actualCourseSelected = getState().courseReducer?.courseSelected;
      const courseId = getState().learnTabReducer.courseData?.courseId;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const data = await fetchUserPerformanceHoverboxData({
        course: returnCourseCamelCaseName(modifiedCourse),
        id: courseId,
      });
      dispatch(setUserPerformanceHoverbox(data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getOnboarding() {
  return async (dispatch, getState) => {
    try {
      const data = await getOnboardingData();
      dispatch(setUserOnboardingData(data));
      dispatch(setOnboardingLoaded(true));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
}

export default userSubscriptionSlice.reducer;
