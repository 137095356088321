import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { useSelector } from 'react-redux';
import { MATOMO_SITE_ID, MATOMO_URL } from 'src/aws-exports';
import { loggedInUserSelector } from 'src/redux/authSlice';

const MatomoWrapper = ({ children }) => {
  const loggedInUser = useSelector(loggedInUserSelector);

  if (!MATOMO_SITE_ID || !MATOMO_URL) {
    return <>{children}</>;
  }

  const config = {
    siteId: MATOMO_SITE_ID,
    urlBase: MATOMO_URL,
    ...(!!loggedInUser ? { userId: loggedInUser.id } : {}),
  };

  const matomoInstance = createInstance(config);

  return <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;
};

export default MatomoWrapper;
